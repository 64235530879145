import React from 'react';
import styled from 'styled-components';
import {message} from 'antd';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import qs from 'query-string';
import GenericForm from 'rev.sdk.js/Generic/Form';
import usePosErrorHandler from '../../Hooks/usePosErrorHandler';
import usePosEdit from '../../Hooks/usePosEdit';

const FormSpec = {
  schema: {
    title: '',
    type: 'object',
    properties: {
      name: {
        type: 'string',
        title: '分店',
        readOnly: true,
      },
      stations: {
        type: 'array',
        title: '所有工作區',
        items: {
          type: 'object',
          title: '工作區設定',
          properties: {
            name: {
              type: 'string',
              title: '名稱',
            },
            type: {
              type: 'string',
              title: '類型',
              enum: ['normal', 'main'],
              enumNames: ['一般工作台', '主控台'],
              default: 'normal',
            },
            sticker_machine: {
              type: 'object',
              title: '貼紙機設定',
              properties: {
                ip: {
                  type: 'string',
                },
                port: {
                  type: 'number',
                  default: 9100,
                },
              },
            },

            note: {
              type: 'string',
              title: '備註',
            },
          },
          dependencies: {
            type: {
              oneOf: [
                {
                  properties: {
                    type: {
                      enum: ['main'],
                    },
                    label_machine: {
                      type: 'object',
                      title: '標籤機設定',
                      properties: {
                        ip: {
                          type: 'string',
                        },
                        port: {
                          type: 'number',
                          default: 9100,
                        },
                      },
                    },
                    invoice_machine: {
                      type: 'object',
                      title: '發票機設定',
                      properties: {
                        ip: {
                          type: 'string',
                        },
                        port: {
                          type: 'number',
                          default: 9100,
                        },
                      },
                    },
                    credit_card_machine: {
                      type: 'object',
                      title: '信用卡機設定',
                      properties: {
                        ip: {
                          type: 'string',
                        },
                        port: {
                          type: 'number',
                          default: 50002,
                        },
                      },
                    },
                  },
                },
                {
                  properties: {
                    type: {
                      enum: ['normal'],
                    },
                  },
                },
              ],
            },
          },
        },
      },
    },
  },
  uiSchema: {
    image: {
      'ui:widget': 'rev-single-image-uploader',
    },
    stations: {
      items: {
        'ui:order': ['*', 'note'],
        name: {
          'ui:placeholder': '請使用中英數，數字只能在最後，請勿使用符號和空格',
          'ui:help': '請使用中英數，數字只能在最後，請勿使用符號和空格',
        },
        note: {
          'ui:widget': 'textarea',
        },
        label_machine: {
          ip: {
            'ui:help': '192.168.123.101',
          },
          port: {
            'ui:help': '9100',
          },
        },
        invoice_machine: {
          ip: {
            'ui:help': '192.168.123.102',
          },
          port: {
            'ui:help': '9100',
          },
        },
        credit_card_machine: {
          ip: {
            'ui:help': '192.168.123.201',
          },
          port: {
            'ui:help': '50002',
          },
        },
        sticker_machine: {
          ip: {
            'ui:help': '192.168.123.151',
          },
          port: {
            'ui:help': '9100',
          },
        },
      },
    },
  },
};

export default function EditPosConfigPage(props) {
  const [store, setStore] = React.useState(null);
  const {name, token} = qs.parse(props.location.search);
  const {errorHandler, renderError} = usePosErrorHandler();

  const {setDone} = usePosEdit({values: {}});

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        setStore(
          await JStorage.fetchOneDocument('store', {name}, null, {token}),
        );
      } catch (ex) {
        console.warn('BadgeDetailPage ex', ex);
        errorHandler(ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [name, token]);

  return (
    <Wrapper>
      <h1>編輯工作區</h1>
      {renderError()}
      {store && (
        <div className="content">
          <GenericForm
            {...FormSpec}
            instance={store}
            onChange={() => {
              setDone(false);
            }}
            onSubmit={async (formData, extValues) => {
              AppActions.setLoading(true);
              try {
                let re = /[`~,.<>;':"/[\]|{}()=_+-]/g;
                let re2 = /^[0-9].*/g;

                formData.stations = formData.stations.map((s) => {
                  return {...s, name: s.name.replace(/\s/g, '')};
                }); // replace space

                if (
                  formData.stations.some(
                    (s) => re.test(s.name) || re2.test(s.name),
                  )
                ) {
                  message.error('名稱格式不對');
                  throw new Error('Station name wrong format.');
                }

                await JStorage.updateDocument('store', {name}, formData, {
                  token,
                });
                setDone(true);
              } catch (ex) {
                console.warn(ex);
                errorHandler(ex);
                message.error('發生錯誤, 無法進行編輯！');
              }
              AppActions.setLoading(false);
            }}
          />
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #eee;
  padding: 12px;
  min-height: 100vh;

  & > h1 {
    font-size: 20px;
    margin-bottom: 6px;
    font-weight: 400;
  }

  & > .content {
    margin: 0 auto;
    border-radius: 6px;
    padding: 12px;
    background-color: white;
  }
`;
