import React from 'react';
import qs from 'query-string';

export default function usePosEdit(props) {
  const {values} = props;
  const queryStr =
    typeof window !== 'undefined' ? window?.location?.search : '';
  const prev = React.useRef(values);

  const setDone = React.useCallback(
    (value) => {
      // to notify webview done
      if (typeof window !== 'undefined') {
        let prev = qs.parse(queryStr);
        if (value) {
          prev.done = value;
          window.history.replaceState(null, null, '?' + qs.stringify(prev));
        } else if (prev.done) {
          delete prev.done;
          window.history.replaceState(null, null, '?' + qs.stringify(prev));
        }
      }
    },
    [queryStr],
  );

  React.useEffect(() => {
    if (JSON.stringify(prev.current) !== JSON.stringify(values)) {
      setDone(false);
    }
    prev.current = values;
  }, [values]);

  return {
    setDone,
  };
}
