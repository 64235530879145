import React from 'react';
import {Alert} from 'antd';

export default function usePosErrorHandler(props) {
  const [error, setError] = React.useState('');

  const errorHandler = React.useCallback((err) => {
    setError(err);
    window.scrollTo(0, 0);
  }, []);

  const render = React.useCallback(() => {
    if (
      error?.status &&
      error?.response &&
      error?.response?.error &&
      error?.response?.detail
    ) {
      return (
        <Alert
          showIcon
          type="error"
          message={error.status}
          description={`錯誤代碼：${error.response.error}\r\n錯誤資訊：${error.response.detail}`}
          style={{
            marginBottom: 6,
            borderRadius: 16,
            whiteSpace: 'break-spaces',
          }}
        />
      );
    } else if (error) {
      return (
        <Alert
          showIcon
          type="error"
          message={'發生錯誤'}
          style={{marginBottom: 6, borderRadius: 16}}
        />
      );
    } else {
      return null;
    }
  }, [error]);

  return {
    error,
    errorHandler,
    renderError: render,
  };
}
